import { Box, Heading } from '@chakra-ui/react';

const Analytics = () => {
  return (
    <Box>
      <Heading>Analytics</Heading>
      {/* Add your dashboard content here */}
    </Box>
  );
};

export default Analytics;